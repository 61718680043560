<template>
  <div class="collectives-main">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ $t("menu.menuCollectiv") }}
          </div>
        </router-link>
      </div>
      <h2 class="events__title">
        {{ $t("menu.menuCollectiv") }}
      </h2>
      <div class="collectives-main__posts">
        <router-link
          tag="div"
          :to="'/collectives/' + item.id"
          class="collectives-main__posts-item"
          v-for="item in collectives"
          :key="item.id"
        >
          <div class="collectives-main__posts-item__img">
            <img :src="item.image || defImg" alt="Post image" />
          </div>
          <div class="collectives-main__posts-item__title">
            {{ item.title[$i18n.locale] }}
          </div>
        </router-link>
      </div>
      <Pagination
        v-model="pagination.page"
        :total="pagination.total"
        :limit="12"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Card from "@/components/EventsCard";
import Pagination from "@/components/Pagination";
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Card,
    Pagination,
    Footer
  },
  data() {
    return {
      defImg: require("@/assets/images/img/collectives_photo.jpg"),
      pagination: {
        page: 1,
        total: 123
      }
    };
  },

  computed: {
    ...mapState({
      collectives: state => state.collectives
    }),
    currentPage() {
      return this.pagination.page;
    }
  },
  watch: {
    currentPage(val) {
      this.$axios
        .get("/collectives", {
          params: {
            page: val
          }
        })
        .then(response => {
          this.setState({ collectives: response.data.data });
          this.pagination.total = response.data.meta.total;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/collectives")
      .then(response => {
        this.setState({ collectives: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
