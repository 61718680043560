<template>
  <div class="science">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ $t("menu.menuScience") }}
          </div>
        </router-link>
      </div>
      <h2 class="events__title">
        {{ $t("menu.menuScience") }}
      </h2>
      <div class="events__posts" v-if="sciences">
        <Card
          v-for="(science, index) in sciences"
          :key="index"
          :event="science"
          route="/events/"
        ></Card>
      </div>
      <Pagination
        v-model="pagination.page"
        :total="pagination.total"
        :limit="12"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import Card from "@/components/EventsCard";
import Pagination from "@/components/Pagination";
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Card,
    Pagination,
    Footer
  },
  data() {
    return {
      pagination: {
        page: 1,
        total: 123
      }
    };
  },

  computed: {
    ...mapState({
      sciences: state => state.sciences
    }),
    currentPage() {
      return this.pagination.page;
    }
  },
  watch: {
    currentPage(val) {
      this.$axios
        .get("/publications/sciences", {
          params: {
            page: val
          }
        })
        .then(response => {
          this.setState({ sciences: response.data.data });
          this.pagination.total = response.data.meta.total;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/publications/sciences")
      .then(response => {
        this.setState({ sciences: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style lang="scss">
.science {
  .container {
    padding-bottom: 100px;
  }
}
</style>
