<template>
  <div class="study">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ $t("menu.menuStudy") }}
          </div>
        </router-link>
      </div>
      <div class="study__img">
        <img src="@/assets/images/img/study__main.jpg" alt="Post Image" />
      </div>
      <div class="study__content">
        <div class="study__post" v-if="firstPost">
          <h2 class="study__post__title">
            {{ firstPost.title[$i18n.locale] }}
          </h2>
          <div
            class="study__post__body"
            v-html="firstPost.body[$i18n.locale]"
          ></div>
        </div>
        <router-view v-if="this.$route.params.id" />
        <div class="study__box">
          <div class="study__links__title">
            {{ $t("study.addLinks") }}
          </div>
          <div v-bar>
            <div class="study__links">
              <router-link
                tag="div"
                :to="'/study/' + link.id"
                class="study__links__item"
                v-for="link in allStudies"
                :key="link.id"
                active-class="study__links__item_active"
              >
                {{ link.title[$i18n.locale] }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Footer
  },
  data() {
    return {
      firstPost: null
    };
  },
  methods: {
    ...mapMutations(["setState"])
  },
  computed: {
    ...mapState({
      allStudies: state => state.allStudies
    }),
    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId(val) {
      console.log(val);
      this.firstPost = false;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.firstPost = false;
    } else {
      this.$axios
        .get("/studies/first")
        .then(response => {
          this.firstPost = response.data.data[0];
        })
        .catch(error => {
          console.log(error);
        });
    }
    this.$axios
      .get("/studies/title")
      .then(response => {
        this.setState({ allStudies: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
