<template>
  <div class="study subevents" v-if="firstPost">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/collectives/" class="breadcrumbs__item ">
          <div>{{ $t("menu.menuCollectiv") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ firstPost.title[$i18n.locale] }}
          </div>
        </router-link>
      </div>
      <div class="study__img">
        <img
          :src="firstPost.image || '@/assets/images/img/study__main.jpg'"
          alt="Post Image"
        />
      </div>
      <div class="study__content">
        <div class="study__post">
          <h2 class="study__post__title">
            {{ firstPost.title[$i18n.locale] }}
          </h2>
          <div class="subevents__info">
            <span>
              {{ createdAt[2] }} {{ $t(months) }}
              {{ createdAt[0] }}
            </span>
            <span>
              {{ firstPost.views }}
              {{ $t("abEvents.views") }}
            </span>
          </div>
          <div
            class="study__post__body"
            v-html="firstPost.body[$i18n.locale]"
          ></div>
        </div>
        <div class="study__wrapper">
          <div class="study__box">
            <div class="study__links__title">
              {{ $t("subEvents.title") }}
            </div>
            <div v-bar>
              <div class="study__links">
                <router-link
                  tag="div"
                  :to="'/events/' + post.id"
                  class="subevents-item"
                  v-for="post in nextPost"
                  :key="post.id"
                >
                  <div class="subevents-item__img">
                    <img :src="firstPost.image" alt="Poster" />
                  </div>
                  <div class="subevents-item__name">
                    <div class="subevents-item__name__title">
                      {{ post.title[$i18n.locale] }}
                    </div>
                    <div class="subevents-item__name__more">
                      {{ $t("more") }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Footer
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      allStudies: state => state.allStudies,
      firstPost: state => state.firstPost,
      nextPost: state => state.nextPost
    }),

    createdAt() {
      let createdAt = this.firstPost.created_at;
      createdAt = createdAt.substr(0, 10).split("-");
      if (createdAt[2] != 10) {
        createdAt[2] = createdAt[2].replace(/(^|\s)0/g, "$1");
      }
      this.months = "months." + createdAt[1];
      return createdAt;
    },
    routeId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  watch: {
    routeId(val) {
      this.$axios
        .get("/collectives/" + val)
        .then(response => {
          this.setState({ nextPost: response.data.next });
          this.setState({ firstPost: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.$axios
      .get("/collectives/" + this.$route.params.id)
      .then(response => {
        this.setState({ nextPost: response.data.next });
        this.setState({ firstPost: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
