<template>
  <div class="afisha">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ $t("menu.menuAfisha") }}
          </div>
        </router-link>
      </div>
      <h2 class="events__title">
        {{ $t("menu.menuAfisha") }}
      </h2>
      <b-carousel
        v-if="afiches"
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000;"
        fade
        indicators
        img-width="1024"
        img-height="480"
        class="afisha__slide"
      >
        <b-carousel-item
          v-for="(afisha, index) in afiches"
          :key="index"
          :afisha="afisha"
        />
      </b-carousel>
      <div class="afisha__content">
        <div class="afisha__content__box">
          <div class="afisha__content__posts" v-if="allAfiches">
            <k-card
              route="/afisha/"
              v-for="afisha in allAfiches"
              :key="afisha.id"
              :event="afisha"
            />

            <k-pagination
              v-model="pagination.page"
              :total="pagination.total"
              v-if="allAfiches.length === 8"
              :limit="8"
            />
          </div>
        </div>

        <div class="cal">
          <div class="cal__box">
            <h3 class="cal__title">{{ $t("menu.menuAfisha") }}</h3>
            <div class="cal__search">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.14279 15C11.9299 15 14.9999 11.9299 14.9999 8.14285C14.9999 4.35575 11.9299 1.28571 8.14279 1.28571C4.35569 1.28571 1.28564 4.35575 1.28564 8.14285C1.28564 11.9299 4.35569 15 8.14279 15Z"
                  stroke="#787885"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.7144 16.7143L12.9858 12.9857"
                  stroke="#787885"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                type="text"
                :placeholder="$t('cal.search')"
                v-model="inputVal"
              />
            </div>
            <h3 class="cal__text">
              {{ $t("cal.text") }}
            </h3>
            <div class="cal__subtext">
              {{ $t("cal.subText") }}
            </div>
            <b-calendar
              v-if="allAfiches"
              :date-info-fn="dateClass"
              :date-disabled-fn="dateDisabled"
              v-model="value"
              :locale="$i18n.locale"
              block
              @context="onContext"
            ></b-calendar>
            <button class="cal__btn" @click="search">
              {{ $t("cal.btn") }}
            </button>
            <router-link
              tag="div"
              class="cal__live"
              to="#"
              v-if="lives === null"
            >
              <pre>{{ lives }}</pre>
              <div class="cal__live__text">
                {{ $t("cal.liveStream") }}
              </div>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0001 3.85718H4.00007C2.7377 3.85718 1.71436 4.88053 1.71436 6.14289V22.1429C1.71436 23.4053 2.7377 24.4286 4.00007 24.4286H20.0001C21.2624 24.4286 22.2858 23.4053 22.2858 22.1429V6.14289C22.2858 4.88053 21.2624 3.85718 20.0001 3.85718Z"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5715 1.57141V6.14284"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.42847 1.57141V6.14284"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.71436 10.7142H22.2858"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
            <router-link
              tag="div"
              class="cal__live"
              :to="live.video"
              v-if="lives"
              v-for="(live, index) in lives"
              :key="index"
            >
              <div class="cal__live__text">
                {{ live.title[$i18n.locale] }}
              </div>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0001 3.85718H4.00007C2.7377 3.85718 1.71436 4.88053 1.71436 6.14289V22.1429C1.71436 23.4053 2.7377 24.4286 4.00007 24.4286H20.0001C21.2624 24.4286 22.2858 23.4053 22.2858 22.1429V6.14289C22.2858 4.88053 21.2624 3.85718 20.0001 3.85718Z"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5715 1.57141V6.14284"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.42847 1.57141V6.14284"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.71436 10.7142H22.2858"
                  stroke="#C9A240"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { BCarousel, BCarouselSlide, BCalendar } from "bootstrap-vue";
import AfishaSliderItem from "@/components/AfishaSliderItem";
import EventsCard from "@/components/EventsCard";
import Pagination from "@/components/Pagination";
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
    "b-carousel-item": AfishaSliderItem,
    "b-cal": BCalendar,
    "k-card": EventsCard,
    "k-pagination": Pagination,
    Footer
  },
  data() {
    return {
      afiches: [],
      postTitles: [],
      titles: [],
      month: null,
      value: "",
      inputVal: "",
      pagination: {
        page: 1,
        total: 123
      }
    };
  },

  computed: {
    ...mapState({
      allAfiches: state => state.allAfiches,
      lives: state => state.lives,
      calendar: state => state.calendar
    }),
    currentPage() {
      return this.pagination.page;
    }
  },
  watch: {
    currentPage(val) {
      this.$axios
        .get("/playbills", {
          params: {
            page: val
          }
        })
        .then(response => {
          let res = response.data.data;
          this.setState({ allAfiches: res });

          this.pagination.total = response.data.meta.total;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"]),
    search() {
      this.$axios
        .post("/playbills/search", {
          query: this.inputVal,
          lang: this.$i18n.locale
        })
        .then(response => {
          let res = response.data.data;
          console.log(res);
          this.setState({ allAfiches: res });
          this.pagination.total = response.data.meta.total;
        })
        .catch(error => {
          console.log("ответ из сервера", error);
        });
    },
    onContext(ymd, date) {
      if (!ymd.selectedYMD) {
        this.month = ymd.activeDate.getMonth();

        this.$axios
          .post("/playbills/calendar", {
            month: this.month + 1
          })
          .then(response => {
            this.setState({ calendar: response.data.data });
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (ymd.selectedYMD) {
        let dateMsecond = ymd.selectedDate.getTime() / 1000;
        this.$axios
          .post("/playbills/calendar/list", {
            date: dateMsecond
          })
          .then(response => {
            let res = response.data.data;
            this.setState({ allAfiches: res });
          })
          .catch(error => {
            console.log("ответ из сервера", error);
          });
      }
    },
    dateClass(ymd, date) {
      const day = ymd;
      // console.log(day);
      let af;
      if (this.calendar) {
        this.calendar.forEach(afisha => {
          afisha.date = afisha.date.split(" ")[0];
          if (day === afisha.date) {
            af = afisha.date;
          }
        });
      }

      return day === af ? "table-info" : "";
    },
    dateDisabled(ymd) {
      const day = ymd;
      // console.log(day);
      let af;
      if (this.calendar) {
        this.calendar.forEach(afisha => {
          afisha.date = afisha.date.split(" ")[0];
          if (day === afisha.date) {
            af = afisha.date;
          }
        });
      }
      return day !== af;
    }
  },
  created() {
    this.$axios
      .get("/playbills/last")
      .then(response => {
        this.afiches = response.data.data;
        for (let i = 0; i <= this.afiches.length; i++) {
          this.afiches[i].created_at = this.afiches[i].created_at
            .substr(0, 10)
            .split("-");
          if (this.afiches[i].created_at[2] != 10) {
            this.afiches[i].created_at[2] = this.afiches[
              i
            ].created_at[2].replace(/(^|\s)0/g, "$1");
          }
          this.titles[i] = this.afiches[i].title;
        }
      })
      .catch(error => {});
    this.$axios
      .get("/playbills")
      .then(response => {
        let res = response.data.data;
        this.setState({ allAfiches: res });
        this.pagination.total = response.data.meta.total;
      })
      .catch(error => {});
    this.$axios.get("/live").then(response => {
      this.setState({ lives: response.data.data });
    });
  }
};
</script>
