<template>
  <div class="study">
    <div class="container">
      <h3 class="events__title">
        {{ $t("menu.menuStudy") }}
      </h3>
      <div class="study__info">
        {{ $t("abStudy.info") }}
      </div>
      <router-link tag="button" to="/study/" class="study__btn">{{
        $t("abStudy.btn")
      }}</router-link>
      <div class="study__usefull">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.52915 9.04678C7.50419 9.02206 7.47048 9.00819 7.43535 9.00819C7.40022 9.00819 7.36652 9.02206 7.34155 9.04678L5.41245 10.9759C4.51929 11.869 3.01187 11.9637 2.02573 10.9759C1.03794 9.98809 1.13257 8.48232 2.02573 7.58916L3.95483 5.66006C4.0063 5.60859 4.0063 5.52393 3.95483 5.47246L3.29409 4.81172C3.26913 4.787 3.23542 4.77314 3.20029 4.77314C3.16516 4.77314 3.13146 4.787 3.10649 4.81172L1.17739 6.74082C-0.2271 8.14531 -0.2271 10.4181 1.17739 11.8209C2.58188 13.2237 4.85464 13.2254 6.25747 11.8209L8.18657 9.8918C8.23804 9.84033 8.23804 9.75566 8.18657 9.7042L7.52915 9.04678ZM11.8223 1.17764C10.4178 -0.226855 8.14507 -0.226855 6.74224 1.17764L4.81147 3.10674C4.78676 3.1317 4.77289 3.16541 4.77289 3.20054C4.77289 3.23567 4.78676 3.26937 4.81147 3.29434L5.47056 3.95342C5.52202 4.00488 5.60669 4.00488 5.65815 3.95342L7.58726 2.02432C8.48042 1.13115 9.98784 1.03652 10.974 2.02432C11.9618 3.01211 11.8671 4.51787 10.974 5.41104L9.04487 7.34014C9.02016 7.3651 9.00629 7.39881 9.00629 7.43394C9.00629 7.46906 9.02016 7.50277 9.04487 7.52774L9.70562 8.18848C9.75708 8.23994 9.84175 8.23994 9.89321 8.18848L11.8223 6.25938C13.2251 4.85488 13.2251 2.58213 11.8223 1.17764ZM8.12847 4.18086C8.10351 4.15614 8.0698 4.14228 8.03467 4.14228C7.99954 4.14228 7.96583 4.15614 7.94087 4.18086L4.18061 7.93945C4.1559 7.96442 4.14203 7.99812 4.14203 8.03325C4.14203 8.06838 4.1559 8.10209 4.18061 8.12705L4.83804 8.78447C4.8895 8.83594 4.97417 8.83594 5.02564 8.78447L8.78423 5.02588C8.83569 4.97441 8.83569 4.88975 8.78423 4.83828L8.12847 4.18086Z"
            fill="#777777"
          />
        </svg>
        <span>{{ $t("abStudy.usefullTitle") }}</span>
      </div>
      <div class="study__links">
        <router-link
          :to="'/study/' + link.id"
          v-for="link in links"
          :key="link.id"
        >
          {{ link.title[$i18n.locale] }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      links: [],
      ownLink: []
    };
  },
  created() {
    this.$axios
      .get("/studies/main")
      .then(response => {
        this.links = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
