<template>
  <div class="pers">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/personalities" class="breadcrumbs__item">
          <div>{{ $t("menu.menuPers") }} &raquo;</div>
        </router-link>
        <router-link
          to="#"
          class="breadcrumbs__item breadcrumbs__item_active"
          v-if="persStaff"
        >
          <div>
            {{ persStaff.title[$i18n.locale] }}
          </div>
        </router-link>
      </div>
      <div class="pers__content">
        <div class="pers__menu">
          <div class="pers__menu__title">
            {{ $t("pers.title") }}
          </div>
          <router-link
            tag="div"
            :to="'/personalities/' + item.id"
            class="pers__menu__item"
            active-class="pers__menu__item_active"
            v-for="item in pers"
            :key="item.id"
          >
            {{ item.title[$i18n.locale] }}
          </router-link>
        </div>
        <div>
          <div class="pers__staff" v-if="persStaff && persStaffValue">
            <h2 class="pers__staff__title">
              {{ persStaff.title[$i18n.locale] }}
            </h2>
            <div
              class="pers__staff__kadr"
              v-for="item in persStaff.staff"
              :key="item.id"
            >
              <div class="pers__staff__img">
                <img
                  :src="item.avatar || '@/assets/images/img/staff_ava.jpg'"
                  alt="Ava"
                />
              </div>
              <div class="pers__staff__name">
                {{ item.name[$i18n.locale] }}
              </div>
              <div class="pers__staff__position">
                {{ item.position[$i18n.locale] }}
              </div>
            </div>
          </div>
          <router-view v-if="this.$route.params.id" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Accordion,
    Footer
  },
  data() {
    return {
      persStaffValue: true
    };
  },
  computed: {
    ...mapState({
      persStaff: state => state.persStaff,
      pers: state => state.personalities
    }),

    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId(val) {
      this.persStaffValue = false;
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    if (this.$route.params.id) {
      this.persStaffValue = false;
    } else {
      this.$axios
        .get("/departments/first")
        .then(response => {
          this.setState({ persStaff: response.data.data[0] });
          console.log("persStaff", this.persStaff);
        })
        .catch(error => {
          console.log(error);
        });
    }
    this.$axios
      .get("/departments/title")
      .then(response => {
        this.setState({ personalities: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
