<template>
  <div class="personalities">
    <div class="container">
      <h3 class="events__title">
        {{ $t("menu.menuPers") }}
      </h3>
      <div class="personalities__select">
        <div class="personalities__select__title">
          {{ $t("abPersonalities.chooseDep") }}
        </div>
        <v-select :options="options" route="'/personalities'" />
        <router-link class="personalities__select__link" to="/personalities">
          {{ $t("abPersonalities.linkDep") }}
        </router-link>
      </div>
    </div>
    <div class="personalities__img">
      <img
        src="@/assets/images/img/personalities_img.jpg"
        alt="Background image"
      />
    </div>
  </div>
</template>
<script>
import CustomSelect from "@/components/CustomSelect";
export default {
  components: {
    "v-select": CustomSelect
  },
  data() {
    return {
      options: null
    };
  },
  created() {
    this.$axios
      .get("/departments/title")
      .then(response => {
        this.options = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
