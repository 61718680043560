var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"histories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"breadcrumbs"},[_c('router-link',{staticClass:"breadcrumbs__item",attrs:{"to":"/"}},[_c('div',[_vm._v(_vm._s(_vm.$t("menu.menuMain"))+" »")])]),_c('router-link',{staticClass:"breadcrumbs__item ",attrs:{"to":"/histories"}},[_c('div',[_vm._v(_vm._s(_vm.$t("menu.menuHistory"))+" »")])]),(_vm.historiesPost && this.$route.name !== 'library')?_c('router-link',{staticClass:"breadcrumbs__item breadcrumbs__item_active",attrs:{"to":"#"}},[_c('div',[_vm._v(" "+_vm._s(_vm.historiesPost.title[_vm.$i18n.locale])+" ")])]):_c('router-link',{staticClass:"breadcrumbs__item breadcrumbs__item_active",attrs:{"to":"#"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("lib"))+" ")])])],1),_c('div',{staticClass:"histories__content"},[_c('div',{staticClass:"histories__menu"},[_c('router-link',{staticClass:"histories__menu__item",attrs:{"tag":"div","to":{ name: 'library' },"active-class":"histories__menu__item_active"}},[_vm._v(" "+_vm._s(_vm.$t("lib"))+" ")]),_vm._l((_vm.histories),function(item){return _c('router-link',{key:item.id,staticClass:"histories__menu__item",attrs:{"tag":"div","to":{ name: 'subhistories', params: { id: item.id } },"active-class":"histories__menu__item_active"}},[_vm._v(" "+_vm._s(item.title[_vm.$i18n.locale])+" ")])})],2),(
          _vm.historiesPost &&
            !this.$route.params.id &&
            this.$route.name !== 'library'
        )?_c('div',{staticClass:"histories__post"},[_c('h2',{staticClass:"histories__post__title"},[_vm._v(" "+_vm._s(_vm.historiesPost.title[_vm.$i18n.locale])+" ")]),_c('div',{staticClass:"histories__post__text",domProps:{"innerHTML":_vm._s(_vm.historiesPost.body[_vm.$i18n.locale])}})]):_vm._e(),(
          this.$route.name === 'subhistories' ||
            this.$route.name === 'library'
        )?_c('router-view'):_vm._e()],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }