<template>
  <div class="select" :class="{ select_full: full }">
    <p class="select__title" @click="isVisible = !isVisible">
      {{ selected[$i18n.locale] || selected }}
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ rotated: isVisible }"
      >
        <path d="M0 0.125H10L5 5.125L0 0.125Z" fill="#777777" />
      </svg>
    </p>
    <div class="select__options" v-if="isVisible">
      <router-link
        :to="'/personalities/' + option.id"
        tag="span"
        v-for="option in options"
        v-if="route"
        :key="option.id"
        @click="selectOptions(option)"
      >
        {{ option.title[$i18n.locale] }}
      </router-link>
      <span v-else @click="selectOptions(option)">
        {{ option.title }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    value: String,
    route: String,
    full: Boolean
  },
  data() {
    return {
      isVisible: false,
      selected: this.$t("form.dep")
    };
  },
  methods: {
    ...mapMutations(["setState"]),
    selectOptions(option) {
      this.selected = option.title;
      this.setState({ selectValue: option.value });
      this.isVisible = false;
    },
    hideSelect() {
      this.isVisible = false;
    }
  },
  mounted() {
    document.addEventListener("click", this.hideSelect.bind(this), true);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideSelect);
  }
};
</script>
<style lang="scss">
.select {
  position: relative;
  width: 456px;
  &_full {
    width: 100%;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eeeeee;
    padding: 9px 15px;
    font-size: 18px;
    line-height: 100%;
    color: #777777;
    margin-bottom: 0;
    cursor: pointer;
    transition: 0.2s;
    border: 1px solid transparent;
    &:hover {
      background: #fcfcfc;
      border-color: #eeeeee;
    }
    svg {
      transition: 0.2s;
      &.rotated {
        transform: rotate(180deg);
      }
    }
  }
  &__options {
    position: absolute;
    top: 36px;
    right: 0;
    width: 100%;
    background: #eeeeee;
    span {
      display: block;
      font-size: 16px;
      line-height: 100%;
      color: #777777;
      transition: 0.2s;
      padding: 5px 15px;
      cursor: pointer;
      &:hover {
        padding-left: 20px;
        background: #fcfcfc;
      }
    }
  }
}
</style>
