<template>
  <div class="container">
    <section class="afisha">
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000;"
        fade
        indicators
        img-width="1024"
        img-height="480"
        class="afisha__slide"
      >
        <b-carousel-item
          v-for="afisha in afiches"
          :key="afisha.id"
          :afisha="afisha"
        />
      </b-carousel>
      <router-link class="events__link" to="/afisha">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25 5.25H6.125V6.125H5.25V5.25ZM7.875 10.5H8.75V11.375H7.875V10.5ZM10.5 5.25H11.375V6.125H10.5V5.25ZM7.875 5.25H8.75V6.125H7.875V5.25ZM5.25 7H6.125V7.875H5.25V7ZM2.625 7H3.5V7.875H2.625V7ZM10.5 7H11.375V7.875H10.5V7ZM7.875 7H8.75V7.875H7.875V7ZM5.25 8.75H6.125V9.625H5.25V8.75ZM2.625 8.75H3.5V9.625H2.625V8.75ZM10.5 8.75H11.375V9.625H10.5V8.75ZM7.875 8.75H8.75V9.625H7.875V8.75ZM5.25 10.5H6.125V11.375H5.25V10.5ZM2.625 10.5H3.5V11.375H2.625V10.5ZM14 0.875V13.125H0V0.875H2.625V0H3.5V0.875H10.5V0H11.375V0.875H14ZM0.875 1.75V3.5H13.125V1.75H11.375V2.625H10.5V1.75H3.5V2.625H2.625V1.75H0.875ZM13.125 12.25V4.375H0.875V12.25H13.125Z"
            fill="black"
          />
        </svg>
        <span>{{ $t("abAfiche.allAfiches") }}</span>
      </router-link>
    </section>
  </div>
</template>
<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import AfishaSliderItem from "@/components/AfishaSliderItem";
export default {
  components: {
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
    "b-carousel-item": AfishaSliderItem
  },
  data() {
    return {
      afiches: null,
      titles: []
    };
  },

  created() {
    this.$axios
      .get("/playbills/last")
      .then(response => {
        this.afiches = response.data.data;
        for (let i = 0; i <= this.afiches.length; i++) {
          this.afiches[i].created_at = this.afiches[i].created_at
            .substr(0, 10)
            .split("-");
          if (this.afiches[i].created_at[2] != 10) {
            this.afiches[i].created_at[2] = this.afiches[
              i
            ].created_at[2].replace(/(^|\s)0/g, "$1");
          }
          this.titles[i] = this.afiches[i].title;
        }
      })
      .catch(error => {});
  }
};
</script>
