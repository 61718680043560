<template>
  <div class="study subevents" v-if="firstPost">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link
          v-if="this.$route.query.goBack === 'science'"
          to="/science/"
          class="breadcrumbs__item "
        >
          <div>{{ $t("menu.menuScience") }} &raquo;</div>
        </router-link>
        <router-link v-else to="/events/" class="breadcrumbs__item ">
          <div>{{ $t("menu.menuEvents") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ firstPost.title[$i18n.locale] }}
          </div>
        </router-link>
      </div>
      <div class="study__img">
        <img
          :src="firstPost.image || '@/assets/images/img/study__main.jpg'"
          alt="Post Image"
        />
      </div>
      <div class="study__content">
        <div class="study__post">
          <h2 class="study__post__title">
            {{ firstPost.title[$i18n.locale] }}
          </h2>
          <div class="subevents__info">
            <span>
              {{ createdAt[2] }} {{ $t(months) }}
              {{ createdAt[0] }}
            </span>
            <span>
              {{ firstPost.views }}
              {{ $t("abEvents.views") }}
            </span>
          </div>
          <div
            class="study__post__body"
            v-html="firstPost.body[$i18n.locale]"
          ></div>
        </div>
        <div class="study__wrapper">
          <div class="study__box">
            <div class="study__links__title">
              {{ $t("subEvents.title") }}
            </div>
            <div v-bar>
              <div class="study__links">
                <router-link
                  tag="div"
                  :to="'/events/' + post.id"
                  class="subevents-item"
                  v-for="post in nextPost"
                  :key="post.id"
                >
                  <div class="subevents-item__img">
                    <img :src="post.image" alt="Poster" />
                  </div>
                  <div class="subevents-item__name">
                    <div class="subevents-item__name__title">
                      {{ post.title[$i18n.locale] }}
                    </div>
                    <div class="subevents-item__name__more">
                      {{ $t("more") }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Footer
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      allStudies: state => state.allStudies,
      firstPost: state => state.firstPost,
      nextPost: state => state.nextPost
    }),
    createdAt() {
      let createdAt = this.firstPost.created_at;
      createdAt = createdAt.substr(0, 10).split("-");
      if (createdAt[2] != 10) {
        createdAt[2] = createdAt[2].replace(/(^|\s)0/g, "$1");
      }
      this.months = "months." + createdAt[1];
      return createdAt;
    },
    routeId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routeId(val) {
      this.$axios
        .get("/publications/" + val)
        .then(response => {
          this.setState({ nextPost: response.data.next });
          console.log("nextPost", this.nextPost);
          this.setState({ firstPost: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    console.log(this.$route);
    this.$axios
      .get("/publications/" + this.$route.params.id)
      .then(response => {
        this.setState({ nextPost: response.data.next });
        this.setState({ firstPost: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/_variables.scss";
@import "~@/assets/scss/base/_mixins.scss";
.subevents {
  &__info {
    display: flex;
    margin-bottom: 30px;
    span {
      display: block;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      height: 14px;
      color: $orange;
      &:first-child {
        padding-right: 23px;
        border-right: 1px solid $orange;

        margin-right: 23px;
      }
    }
  }
  &-item {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;

    border-bottom: 1px dashed #cccccc;
    border-right: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      border-bottom-style: solid;
      border-right-color: #cccccc;
    }
    &__img {
      width: 125px;
      min-width: 125px;
      height: 70px;
      margin-right: 10px;
      img {
        @include img;
      }
    }
    &__name {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        color: $text-color;
      }
      &__more {
        font-weight: bold;
        font-size: 10px;
        line-height: 13px;
        color: $orange;
      }
    }
  }
  .study__wrapper {
    width: 30%;
  }
  .study__box {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(204, 204, 204, 0.5);
    border-radius: 2px;
    padding: 30px;
  }
  @media (max-width: 1100px) {
    .study__wrapper {
      width: 320px;
    }
  }
  @media (max-width: 767px) {
    .study__wrapper {
      width: 100%;
    }
  }
  .study__post__title {
    margin-bottom: 15px;
  }
  .study__links {
  }
  .study__links__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    color: #777777;
  }
}
</style>
