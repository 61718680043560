<template>
  <div class="home">
    <section class="promo">
      <div class="promo__bg">
        <img src="@/assets/images/img/orchestra.jpg" alt="Background-photo" />
      </div>
    </section>
    <HomeEvents></HomeEvents>
    <h3 class="home__title">
      {{ $t("menu.menuAfisha") }}
    </h3>
    <div class="afisha_bg">
      <HomeAfisha />
      <HomeStudy />
      <HomeScience />
    </div>
    <HomeStruct />
    <HomePersonalities />
    <div class="afisha_bg_second">
      <HomeHistory />
      <HomeCollectives />
      <HomePartners />
    </div>
    <Footer />
  </div>
</template>
<script>
import HomeEvents from "@/views/homeSections/HomeEvents";
import HomeAfisha from "@/views/homeSections/HomeAfisha";
import HomeStudy from "@/views/homeSections/HomeStudy";
import HomeScience from "@/views/homeSections/HomeScience";
import HomeStruct from "@/views/homeSections/HomeStruct";
import HomePersonalities from "@/views/homeSections/HomePersonalities";
import HomeHistory from "@/views/homeSections/HomeHistory";
import HomeCollectives from "@/views/homeSections/HomeCollectives";
import HomePartners from "@/views/homeSections/HomePartners";
import Footer from "@/views/Footer";
export default {
  components: {
    HomeEvents,
    HomeAfisha,
    HomeStudy,
    HomeScience,
    HomeStruct,
    HomePersonalities,
    HomeHistory,
    HomeCollectives,
    HomePartners,
    Footer
  },
  data() {
    return {
      selected: "first"
    };
  }
};
</script>
