<template>
  <div class="lib">
    <h2 class="lib__title">
      {{ $t("lib") }}
    </h2>

    <a
      :href="`https://api.conservatory.uz/api/histories/library/${item.id}`"
      download
      class="lib__link"
      v-for="item in lib"
    >
      <svg
        width="20"
        height="23"
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 18.5H20V23H0.5V18.5H2V21.5H18.5V18.5ZM16.7773 12.2773L10.25 18.8047L3.72266 12.2773L4.77734 11.2227L9.5 15.9336V0.5H11V15.9336L15.7227 11.2227L16.7773 12.2773Z"
          fill="#C9A240"
        />
      </svg>
      <span>{{ item.file_name }} </span>
    </a>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      file: null
    };
  },
  computed: {
    ...mapState({
      lib: state => state.lib
    })
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    console.log(this.$route.params);
    this.$axios
      .get("/histories/library")
      .then(response => {
        console.log("Links", response.data.data);
        this.setState({ lib: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/_mixins.scss";
@import "~@/assets/scss/base/_variables.scss";
.lib {
  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: $title-color;
    margin-bottom: 30px;
  }
  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    text-decoration: none !important;
    &:hover {
      span {
        border-bottom: 1px solid $orange;
      }
    }
    svg {
      margin-right: 13px;
    }
    span {
      transition: 0.2s;
      font-size: 18px;
      line-height: 150%;
      color: $orange;
      border-bottom: 1px dashed $orange;
    }
  }
}
</style>
