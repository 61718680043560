<template>
  <div class="pers__staff" v-if="persStaff">
    <h2 class="pers__staff__title">
      {{ persStaff.title[$i18n.locale] }}
    </h2>
    <div
      class="pers__staff__kadr"
      v-for="item in persStaff.staff"
      :key="item.id"
    >
      <div class="pers__staff__img">
        <img :src="item.avatar || defImg" alt="Ava" />
      </div>
      <div class="pers__staff__name">
        {{ item.name[$i18n.locale] }}
      </div>
      <div class="pers__staff__position">
        {{ item.position[$i18n.locale] }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      defImg: require("@/assets/images/img/staff_ava.jpg")
    };
  },
  computed: {
    ...mapState({
      persStaff: state => state.persStaff
    }),
    routerId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  watch: {
    routerId(val) {
      this.$axios
        .get("/departments/" + val)
        .then(response => {
          this.setState({ persStaff: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.$axios
      .get("/departments/" + this.$route.params.id)
      .then(response => {
        this.setState({ persStaff: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
