<template>
  <div class="science">
    <div class="container">
      <h2 class="events__title">
        {{ $t("menu.menuScience") }}
      </h2>

      <div class="science__info">
        <div class="science__info__text">
          {{ $t("abScience") }}
        </div>
        <div class="science__info__img">
          <img src="@/assets/images/img/home_science.jpg" alt="" />
        </div>
      </div>
      <router-link to="/science/" tag="button" class="home__btn">{{
        $t("more")
      }}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
