<template>
  <div class="d-pagination" v-show="totalCount">
    <!-- prev button -->
    <div
      :class="[
        'd-pagination-btn d-pagination-btn-nav',
        { 'd-pagination-btn-disabled': value == 1 || loading }
      ]"
      @click="emitPage(value - 1)"
    >
      <svg
        width="9"
        height="12"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.06047 0.56C4.5938 1.8 4.08714 2.82 3.54047 3.62C3.00714 4.42 2.36714 5.14 1.62047 5.78C2.34047 6.40667 2.9738 7.14 3.52047 7.98C4.06714 8.82 4.58047 9.87333 5.06047 11.14L4.72047 11.28C4.14714 10.0133 3.4938 8.94 2.76047 8.06C2.02714 7.16667 1.14714 6.43333 0.120469 5.86V5.84C1.14714 5.26667 2.02714 4.54 2.76047 3.66C3.4938 2.76667 4.14714 1.68667 4.72047 0.419999L5.06047 0.56ZM8.56047 0.56C8.0938 1.8 7.58714 2.82 7.04047 3.62C6.50714 4.42 5.86714 5.14 5.12047 5.78C5.84047 6.40667 6.4738 7.14 7.02047 7.98C7.56714 8.82 8.08047 9.87333 8.56047 11.14L8.22047 11.28C7.64714 10.0133 6.9938 8.94 6.26047 8.06C5.52714 7.16667 4.64714 6.43333 3.62047 5.86V5.84C4.64714 5.26667 5.52714 4.54 6.26047 3.66C6.9938 2.76667 7.64714 1.68667 8.22047 0.419999L8.56047 0.56Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>

    <!-- page number buttons -->
    <div
      :class="[
        'd-pagination-btn',
        { active: +value == +item, 'd-pagination-btn-disabled': loading }
      ]"
      :key="item"
      v-for="item in pagesSlice"
      @click="emitPage(item)"
    >
      {{ item }}
    </div>

    <!-- next button -->
    <div
      :class="[
        'd-pagination-btn d-pagination-btn-nav',
        { 'd-pagination-btn-disabled': value >= totalCount || loading }
      ]"
      @click="emitPage(value + 1)"
    >
      <svg
        width="9"
        height="12"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.790234 0.44C1.36357 1.70667 2.0169 2.78667 2.75023 3.68C3.48357 4.56 4.36357 5.28667 5.39023 5.86V5.88C4.36357 6.45333 3.48357 7.18667 2.75023 8.08C2.0169 8.96 1.36357 10.0333 0.790234 11.3L0.450234 11.16C0.916901 9.92 1.4169 8.9 1.95023 8.1C2.4969 7.3 3.14357 6.58 3.89023 5.94C3.17023 5.31333 2.5369 4.58 1.99023 3.74C1.44357 2.9 0.930234 1.84667 0.450234 0.579999L0.790234 0.44ZM4.29023 0.44C4.86357 1.70667 5.5169 2.78667 6.25023 3.68C6.98357 4.56 7.86357 5.28667 8.89023 5.86V5.88C7.86357 6.45333 6.98357 7.18667 6.25023 8.08C5.5169 8.96 4.86357 10.0333 4.29023 11.3L3.95023 11.16C4.4169 9.92 4.9169 8.9 5.45023 8.1C5.9969 7.3 6.64357 6.58 7.39023 5.94C6.67023 5.31333 6.0369 4.58 5.49023 3.74C4.94357 2.9 4.43023 1.84667 3.95023 0.579999L4.29023 0.44Z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    total: {
      // total count
      type: [Number, String],
      default: () => 0
    },
    limit: {
      // per page
      type: Number,
      default: () => 10
    },
    btnCount: {
      // size of slice
      type: [Number, String],
      default: () => 3
    },
    value: {
      // page number
      type: [Number, String],
      default: () => -1
    },
    navigate: {
      // use $router.push ?
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    totalCount() {
      return Math.ceil((+this.total || 0) / (+this.limit || 20));
    },

    pagesSlice() {
      const btnCount = parseInt(this.btnCount);
      const pageIndexNumber = parseInt(this.value ? this.value - 1 : 0);
      const buttonsCount = Array.from(
        { length: this.totalCount },
        (_, x) => x + 1
      );

      const offset = buttonsCount.length - this.value;

      if (offset < btnCount - 1) {
        // console.log('--', offset, btnCount);
        return buttonsCount
          .slice(buttonsCount.length - btnCount)
          .slice(0, btnCount);
      }

      if (pageIndexNumber > 0) {
        // console.log(2);
        const sliceOffset = this.value + btnCount;
        return buttonsCount
          .slice(pageIndexNumber - 1, sliceOffset)
          .slice(0, btnCount);
      }

      // console.log(3);

      return buttonsCount.slice(pageIndexNumber, pageIndexNumber + btnCount);
      // .splice( || 1, 0);
    }
  },

  methods: {
    emitPage(pageNumber) {
      if (pageNumber <= 0 || pageNumber > this.totalCount) {
        return;
      }

      this.$emit("change", pageNumber);

      if (this.navigate) {
        this.$router.push({
          query: {
            ...this.$router.query,
            page: pageNumber
          }
        });
      }
    }
  }
};
</script>
