<template>
  <div class="study__post" v-if="post">
    <h2 class="study__post__title">
      {{ post.title[$i18n.locale] }}
    </h2>
    <div class="study__post__body" v-html="post.body[$i18n.locale]"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      post: null
    };
  },
  computed: {
    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId() {
      this.fetchStudies();
    }
  },
  methods: {
    fetchStudies() {
      this.$axios
        .get("/studies/" + this.$route.params.id)
        .then(response => {
          this.post = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.fetchStudies();
  }
};
</script>
