<template>
  <div class="histories__post" v-if="historiesPost">
    <h2 class="histories__post__title">
      {{ historiesPost.title[$i18n.locale] }}
    </h2>
    <div
      class="histories__post__text"
      v-html="historiesPost.body[$i18n.locale]"
    ></div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      historiesPost: state => state.historiesPost,
      histories: state => state.histories
    }),
    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId(val) {
      // this.historiesPost = false;
      this.$axios
        .get("/histories/" + val)
        .then(response => {
          this.setState({ historiesPost: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/histories/" + this.$route.params.id)
      .then(response => {
        this.setState({ historiesPost: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
