<template>
  <div class="partners-main">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/" class="breadcrumbs__item breadcrumbs__item_active">
          <div>
            {{ $t("menu.menuPartners") }}
          </div>
        </router-link>
      </div>
      <h2 class="events__title">
        {{ $t("menu.menuPartners") }}
      </h2>
      <VueSlickCarousel v-bind="settings">
        <router-link
          to="#"
          tag="div"
          v-for="partner in partners"
          :key="partner.id"
          class="partners-main__img"
        >
          <img :src="partner.logo" alt="Logo" />
        </router-link>
      </VueSlickCarousel>
    </div>
    <Footer />
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Footer from "@/views/Footer";
export default {
  name: "MyComponent",
  components: { VueSlickCarousel, Footer },
  data() {
    return {
      defLogo: require("@/assets/images/img/afish_bg.jpg"),
      partners: {
        0: {
          defImg: this.defLogo
        },
        1: {
          defImg: this.defLogo
        },
        2: {
          defImg: this.defLogo
        },
        3: {
          defImg: this.defLogo
        },
        5: {
          defImg: this.defLogo
        },
        6: {
          defImg: this.defLogo
        },
        7: {
          defImg: this.defLogo
        }
      },
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  created() {
    this.$axios
      .get("/partners")
      .then(response => {
        this.partners = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/_mixins.scss";
.partners-main {
  height: calc(100% - 74px);
  .container {
    height: calc(100% - 367px);
    min-height: 450px;
    padding-bottom: 100px;
  }
  &__img {
    width: 195px;
    min-width: 195px;
    height: 200px;
    display: flex !important;
    align-items: center;
    img {
      width: 100%;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
}
</style>
