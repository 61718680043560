<template>
  <span class="glasses">
    <!-- <i class="icons icon-reading-glasses" /> -->
    <b-dropdown id="dropdown-grouped" right class="sv-dd">
      <b-dropdown-group id="dropdown-group-2">
        <h3 class="glasses-title">{{ $t("special_view.view_title") }}</h3>
        <div class="glasses-images">
          <div class="glasses-images__row">
            <div class="glasses-images__col">
              <div
                class="glasses-images__item glasses-images__item--normal"
                @click="specialNormal"
              >
                A
              </div>
            </div>
            <div class="glasses-images__col">
              <div
                class="glasses-images__item glasses-images__item--white"
                @click="specialWhite"
              >
                A
              </div>
            </div>
            <div class="glasses-images__col">
              <div
                class="glasses-images__item glasses-images__item--dark"
                @click="specialDark"
              >
                A
              </div>
            </div>
            <div class="glasses-images__col">
              <div
                class="glasses-images__item glasses-images__item--noImage"
                :class="{ setImage: !spcImage }"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/images/icons/eye.svg') +
                    ')!important'
                }"
                @click="specialImage"
              />
            </div>
          </div>
        </div>
      </b-dropdown-group>

      <b-dropdown-group id="dropdown-group-3">
        <h3 class="glasses-title">{{ $t("special_view.text_size") }}</h3>
        <span
          v-if="$i18n.locale == 'en' || $i18n.locale == 'ru'"
          class="glasses-subtitle"
          >{{ $t("special_view.text_value") }} {{ sizeValue }}%</span
        >
        <span v-else class="glasses-subtitle"
          >{{ sizeValue }}% {{ $t("special_view.text_value") }}</span
        >
        <div class="glasses-range mt-2">
          <b-form-input
            id="rangeSize"
            v-model="sizeValue"
            type="range"
            min="0"
            max="100"
            @change="changeSize"
          />
        </div>
      </b-dropdown-group>
    </b-dropdown>
  </span>
</template>

<script>
export default {
  data: () => ({
    searchModal: false,
    spcImage: true,
    sizeValue: 0,
    oldPlusValue: 0
  }),
  watch: {
    $route() {
      this.setFontSize(this.sizeValue);
    }
  },
  mounted() {
    if (this.getCookie("oldRangeValue") != 0) {
      this.sizeValue = this.getCookie("oldRangeValue");
      this.setFontSize(this.sizeValue);
    }
    if (this.getCookie("specialType")) {
      this.$bus.$emit("specialType", this.getCookie("specialType"));
    }
    if (this.getCookie("specialImage")) {
      this.spcImage = parseInt(this.getCookie("specialImage"));
      this.$bus.$emit("specialImage", this.spcImage);
    }
  },
  methods: {
    specialWhite() {
      this.$bus.$emit("specialType", "specialWhite");
      document.cookie = `specialType = specialWhite`;
    },
    specialDark() {
      this.$bus.$emit("specialType", "specialDark");
      document.cookie = `specialType = specialDark`;
    },
    specialNormal() {
      this.$bus.$emit("specialType", "specialNormal");
      document.cookie = `specialType = specialNormal`;
    },
    specialImage() {
      this.spcImage = !this.spcImage;
      this.$bus.$emit("specialImage", this.spcImage);
      document.cookie = `specialImage = ${this.spcImage ? 1 : 0}`;
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setFontSize(value) {
      let min = 16;
      let max = 22;
      let plusSize = Math.round(Math.abs((value * (max - min)) / 100));

      const textTags = document.querySelectorAll(
        "p, a, span, strong, h1, h2, h3, h4, h5, h6, li, i, button"
      );

      if (textTags) {
        Array.from(textTags).forEach(el => {
          let oldValue = parseInt(
            window.getComputedStyle(el, null).getPropertyValue("font-size")
          );
          if (this.sizeValue != 0) {
            el.style.fontSize = oldValue - this.oldPlusValue + plusSize + "px";
          } else {
            el.style.fontSize = "";
          }
        });
      }
      this.oldPlusValue = plusSize;

      document.cookie = `oldRangeValue = ${this.sizeValue}`;
    },
    changeSize() {
      this.setFontSize(this.sizeValue);
    }
  }
};
</script>

<style lang="scss" scoped></style>
