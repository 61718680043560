import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mainMenu: false,
    allAfiches: null,
    afiches: null,
    allStudies: null,
    lives: null,
    sciences: null,
    accordion: null,
    struct: null,
    personalities: null,
    persStaff: null,
    histories: null,
    historiesPost: null,
    collectives: null,
    events: null,
    firstPost: null,
    nextPost: null,
    afishaPost: null,
    lib: null,
    selectValue: "",
    calendar: null,
    globalDOM: null
  },
  mutations: {
    setState(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key in state) {
          state[key] = payload[key];
        }
      });
    }
  }
});
