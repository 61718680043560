<template>
  <div class="homestruct">
    <div class="container">
      <h3 class="events__title">
        {{ $t("menu.menuStruct") }}
      </h3>
      <div class="accordion" role="tablist">
        <Accordion
          v-for="(item, index) in accordion"
          :key="index"
          :accordionItem="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Accordion
  },
  computed: {
    ...mapState({
      accordion: state => state.accordion
    })
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/structures")
      .then(response => {
        this.setState({ accordion: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
