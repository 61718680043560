<template>
  <b-card no-body>
    <b-card-header header-tag="header" role="tab">
      <b-button
        block
        v-b-toggle="'accordion-' + index"
        @click="active = !active"
      >
        <span v-if="active === false">+</span>
        <span class="minus" v-else-if="active === true">-</span>
        {{ accordionItem.name[$i18n.locale] }}
      </b-button>
    </b-card-header>
    <b-collapse :id="'accordion-' + index" :visible="visible == 0 || false">
      <b-card-body>
        <router-link
          :to="'/structure/' + subitem.id"
          tag="b-card-text"
          v-for="subitem in accordionItem.substructures"
          :key="subitem.id"
          active-class="active"
        >
          {{ subitem.title[$i18n.locale] }}
        </router-link>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import { BCollapse } from "bootstrap-vue";

export default {
  props: {
    accordionItem: {
      type: Object
    },
    index: Number,
    visible: Number
  },
  components: {
    BCollapse
  },
  data() {
    return {
      accordionClass: "accordion" + this.index,
      active: false,
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
        `
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/base/_variables.scss";
.active {
  color: $orange !important;
}
</style>
