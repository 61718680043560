import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Events from "@/views/Events";
import Afisha from "@/views/Afisha";
import Collectives from "@/views/Collectives";
import Histories from "@/views/Histories";
import Partners from "@/views/Partners";
import Personalities from "@/views/Personalities";
import Science from "@/views/Science";
import Structure from "@/views/Structure";
import Study from "@/views/Study";
import SubEvents from "@/views/SubEvents/SubEvents";
import SubCollectives from "@/views/SubCollectives/SubCollectives";
import SubAfisha from "@/views/SubAfisha/SubAfisha";
import SubStudy from "@/views/subStudy/SubStudy";
import SubPers from "@/views/SubPers/SubPers";
import Lib from "@/views/SubHistories/Library";
import SubHistories from "@/views/SubHistories/SubHistories";
import SubStruct from "@/views/SubStruct/SubStruct";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/afisha",
    name: "afisha",
    component: Afisha
  },
  {
    path: "/afisha/:id",
    name: "subafisha",
    component: SubAfisha
  },
  {
    path: "/events",
    name: "events",
    component: Events
  },
  {
    path: "/events/:id",
    name: "subevents",
    component: SubEvents
  },
  {
    path: "/partners",
    name: "partners",
    component: Partners
  },
  {
    path: "/histories",
    name: "histories",
    component: Histories,
    children: [
      {
        path: ":id",
        name: "subhistories",
        component: SubHistories
      },
      {
        path: "lib",
        name: "library",
        component: Lib
      }
    ]
  },
  {
    path: "/personalities",
    name: "personlities",
    component: Personalities,
    children: [
      {
        path: ":id",
        component: SubPers
      }
    ]
  },
  {
    path: "/science",
    name: "science",
    component: Science
  },
  {
    path: "/structure",
    name: "structure",
    component: Structure,
    children: [
      {
        path: ":id",
        component: SubStruct
      }
    ]
  },
  {
    path: "/study",
    name: "study",
    component: Study,
    children: [
      {
        path: ":id",
        component: SubStudy
      }
    ]
  },
  {
    path: "/collectives",
    name: "collectives",
    component: Collectives
  },
  {
    path: "/collectives/:id",
    name: "subcollectives",
    component: SubCollectives
  }
];

const router = new VueRouter({
  routes
});

export default router;
