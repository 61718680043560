import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/i18n";
import "@/assets/fonts/stylesheet.css";
import "@/assets/scss/main.scss";
import Vuebar from "vuebar";
import Vuelidate from "vuelidate";
import "@/plugins/eventBus";
import {
  BootstrapVue,
  CarouselPlugin,
  CollapsePlugin,
  CalendarPlugin,
  ModalPlugin,
  NavbarPlugin
} from "bootstrap-vue";
import axios from "@/plugins/axios";

Vue.use(BootstrapVue);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(CalendarPlugin);
Vue.use(ModalPlugin);
Vue.use(Vuebar);
Vue.use(NavbarPlugin);
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
