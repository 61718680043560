<template>
  <div class="struct__content__post" v-if="struct">
    <h2 class="struct__content__title">
      {{ struct.title[$i18n.locale] }}
    </h2>
    <div class="struct__content__text" v-html="struct.body[$i18n.locale]"></div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      struct: state => state.struct
    }),
    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId(val) {
      this.$axios
        .get("/structures/" + val)
        .then(response => {
          this.setState({ struct: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/structures/" + this.$route.params.id)
      .then(response => {
        this.setState({ struct: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
