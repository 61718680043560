import axios from "axios";
// import router from "../router";
// import { getToken } from "@/constants/utils/storage";
// import store from "@/store";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    headers: {
        "Content-Type": "application/json"
    }
});

// const isDev = process.env.NODE_ENV !== "production";
//
// axiosInstance.interceptors.request.use(
//     config => {
//         const storageToken = getToken();
//         if (storageToken) {
//             config.headers["Authorization"] = storageToken ? `Bearer ${storageToken}` : "";
//         }
//
//         return config;
//     },
//
//     error => {
//         console.log("on req error", error);
//         return Promise.resolve(error);
//     }
// );
//
// function emitRouterName(routeName) {
//     emitCustomEvent("d-router-redirect", routeName);
// }
//
// function emitCustomEvent(eventName, eventData) {
//     document.dispatchEvent(
//         new CustomEvent(eventName, {
//             detail: { name: eventData }
//         })
//     );
// }
//
// let count = 0;
// axiosInstance.interceptors.response.use(
//     response => {
//         // Any status code that lie within the range of 2xx cause this function to trigger
//         // Do something with response data
//         if (isDev) {
//             // console.log(response);
//             // console.log(response.data);
//         }
//
//         if (store.state.auth.token && count < 1) {
//             count++;
//             store.dispatch("auth/whoami");
//         } else {
//             count = 0;
//         }
//         // return response;
//         return response || {};
//     },
//     error => {
//         // Any status codes that falls outside the range of 2xx cause this function to trigger
//         // Do something with response error
//         const statusCode = (error.response || {}).status || -1;
//         const errorProps = error;
//
//         if (statusCode == -1) {
//             emitCustomEvent("response-unknown-error", errorProps);
//             // delete axiosInstance.defaults.headers.common["Authorization"];
//             // store.dispatch("auth/logout");
//             // emitRouterName("login");
//             // console.warn(JSON.stringify(errorProps, null, 2));
//             return Promise.reject(errorProps);
//         }
//
//         if (statusCode === 400) {
//             // Исправлен баг с бесконечным обновлением токена
//             if (/(\/refresh_token)/gm.test(errorProps.request.responseURL)) {
//                 delete axiosInstance.defaults.headers.common["Authorization"];
//                 store.dispatch("auth/logout");
//                 emitRouterName("login");
//                 return Promise.reject(errorProps);
//             }
//
//             return Promise.resolve(errorProps);
//         }
//
//         if (statusCode === 401) {
//             if (!error.config.headers["Authorization"]) {
//                 emitRouterName("login");
//                 return Promise.reject(errorProps);
//             }
//
//             if (/(\/refresh_token)/gm.test(errorProps.request.responseURL)) {
//                 delete axiosInstance.defaults.headers.common["Authorization"];
//                 store.dispatch("auth/logout");
//                 emitRouterName("login");
//                 return Promise.reject(errorProps);
//             }
//
//             store.dispatch("auth/refreshToken");
//
//             emitRouterName("refreshToken");
//             return Promise.reject(errorProps);
//             // return Promise.resolve(errorProps);
//         }
//
//         if (statusCode === 403) {
//             // delete axiosInstance.defaults.headers.common["Authorization"];
//             // localStorage.removeItem("tokens");
//             emitRouterName("login");
//             return Promise.resolve(errorProps);
//         }
//         return Promise.resolve(errorProps);
//     }
// );

export default axiosInstance;
