<template>
  <div class="struct">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div class="main">{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/structure" class="breadcrumbs__item">
          <div>{{ $t("menu.menuStruct") }} &raquo;</div>
        </router-link>
        <router-link
          to="#"
          class="breadcrumbs__item breadcrumbs__item_active"
          v-if="struct"
        >
          <div>
            {{ struct.title[$i18n.locale] }}
          </div>
        </router-link>
      </div>
      <div class="struct__content">
        <div class="accordion accordion_struct" role="tablist">
          <Accordion
            v-for="(item, index) in accordion"
            :key="item.id"
            :accordionItem="item"
            :index="index"
            :visible="index"
          />
        </div>
        <div
          class="struct__content__post"
          v-if="struct && !this.$route.params.id"
        >
          <h2 class="struct__content__title">
            {{ struct.title[$i18n.locale] }}
          </h2>
          <div
            class="struct__content__text"
            v-html="struct.body[$i18n.locale]"
          ></div>
        </div>
        <router-view v-if="this.$route.params.id" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Accordion,
    Footer
  },
  computed: {
    ...mapState({
      accordion: state => state.accordion,
      struct: state => state.struct
    }),

    routerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routerId() {
      this.$axios
        .get("/structures")
        .then(response => {
          this.setState({ accordion: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    if (this.$route.params.id) {
    } else {
      this.$axios.get("/structures/first").then(response => {
        this.setState({ struct: response.data.data });
      });
    }
    this.$axios
      .get("/structures")
      .then(response => {
        this.setState({ accordion: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
