<template>
  <div class="histories">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/" class="breadcrumbs__item">
          <div>{{ $t("menu.menuMain") }} &raquo;</div>
        </router-link>
        <router-link to="/histories" class="breadcrumbs__item ">
          <div>{{ $t("menu.menuHistory") }} &raquo;</div>
        </router-link>
        <router-link
          to="#"
          class="breadcrumbs__item breadcrumbs__item_active"
          v-if="historiesPost && this.$route.name !== 'library'"
        >
          <div>
            {{ historiesPost.title[$i18n.locale] }}
          </div>
        </router-link>
        <router-link
          to="#"
          class="breadcrumbs__item breadcrumbs__item_active"
          v-else
        >
          <div>
            {{ $t("lib") }}
          </div>
        </router-link>
      </div>
      <div class="histories__content">
        <div class="histories__menu">
          <router-link
            tag="div"
            :to="{ name: 'library' }"
            class="histories__menu__item"
            active-class="histories__menu__item_active"
          >
            {{ $t("lib") }}
          </router-link>
          <router-link
            tag="div"
            :to="{ name: 'subhistories', params: { id: item.id } }"
            class="histories__menu__item"
            v-for="item in histories"
            :key="item.id"
            active-class="histories__menu__item_active"
          >
            {{ item.title[$i18n.locale] }}
          </router-link>
        </div>
        <div
          class="histories__post"
          v-if="
            historiesPost &&
              !this.$route.params.id &&
              this.$route.name !== 'library'
          "
        >
          <h2 class="histories__post__title">
            {{ historiesPost.title[$i18n.locale] }}
          </h2>
          <div
            class="histories__post__text"
            v-html="historiesPost.body[$i18n.locale]"
          ></div>
        </div>
        <router-view
          v-if="
            this.$route.name === 'subhistories' ||
              this.$route.name === 'library'
          "
        />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/views/Footer";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Footer
  },

  computed: {
    ...mapState({
      histories: state => state.histories,
      historiesPost: state => state.historiesPost
    })
  },
  methods: {
    ...mapMutations(["setState"])
  },
  created() {
    this.$axios
      .get("/histories/title")
      .then(response => {
        this.setState({ histories: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
    if (this.$route.path === "/histories/lib") {
      this.persStaffValue = false;
      this.$router.push({ name: "library" });
    } else {
      this.$axios
        .get("/histories/first")
        .then(response => {
          this.setState({ historiesPost: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
