<template>
  <div
    class="conservatory"
    ref="home"
    :class="[{ specialNoImage: !specialImage }, specialType]"
  >
    <main-menu :mainMenu="mainMenu" />
    <router-view :mainMenu="mainMenu" />

    <button
      class="back_to_top"
      :class="{ 'back_to_top-show': showBtn }"
      @click="backToTop"
    >
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM25.234 16.8745L12.7798 29.3288L14.2882 30.8373L25.234 19.8915L36.1799 30.8373L37.6883 29.3288L25.234 16.8745Z"
          fill="#C9A240"
        />
      </svg>
    </button>
  </div>
</template>
<script>
import mainMenu from "@/components/MainMenu";
import { mapMutations } from "vuex";
export default {
  components: {
    mainMenu
  },
  data() {
    return {
      showBtn: false,
      mainMenu: false,
      specialType: "",
      specialImage: true
    };
  },
  methods: {
    ...mapMutations(["setState"]),
    trackScroll() {
      let scrolled = window.pageYOffset,
        coords = document.documentElement.clientHeight;
      if (scrolled > coords) {
        this.showBtn = true;
      }
      if (scrolled < coords) {
        this.showBtn = false;
      }
    },
    backToTop() {
      if (window.pageYOffset > 0) {
        window.scrollBy(0, -80);
        setTimeout(this.backToTop, 0);
      }
    }
  },
  mounted() {
    this.$bus.$on("specialType", data => {
      this.specialType = data;
    });

    this.$bus.$on("specialImage", data => {
      this.specialImage = data;
    });
    window.addEventListener("scroll", this.trackScroll.bind(this));
    this.setState({
      globalDOM: this.$refs.home
    });
  },
  beforeDestroy() {
    this.$bus.$off("specialType", () => (this.specialType = null));

    this.$bus.$off("specialImage", () => (this.specialImage = null));
  },
  created() {
    if (this.$route.name !== "home") {
      this.mainMenu = true;
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/_variables.scss";
.conservatory {
  height: 100%;
  .back_to_top {
    position: fixed;
    bottom: 40px;
    right: 77px;
    z-index: 9999;
    background: transparent;
    color: #444;
    cursor: pointer;
    border: none;
    display: none;
    outline: none;
    @media (max-width: 700px) {
      right: 40px;
    }

    svg {
      transition: 0.2s;
    }
  }

  .back_to_top:hover {
    svg {
      transform: scale(1.2);
    }
  }

  .back_to_top-show {
    display: block;
  }
}
</style>
