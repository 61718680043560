<template>
  <div class="history">
    <div class="container">
      <h3 class="events__title">
        {{ $t("menu.menuHistory") }}
      </h3>
      <div class="history__links">
        <router-link
          :to="'histories/' + link.id"
          v-for="link in links"
          :key="link.id"
        >
          {{ link.title[$i18n.locale] }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      links: [],
      ownLink: []
    };
  },
  created() {
    this.$axios
      .get("/histories/title")
      .then(response => {
        this.links = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
